export class CreateWMSMaterialBestandRequest {
  bestandid: number;
  bestandTyp: number; // 1 = Karton, 2 = Flasche, 3 = Kapseln, 4 = Etiketten

  constructor(bestandid: number, bestandTyp: number) {
    this.bestandid = bestandid;
    this.bestandTyp = bestandTyp;
  }
}

export class WMSMaterialBestandUmlagernRequest {
  bestandid: number | null;
  bestandTyp: number; // 1 = Karton, 2 = Flasche, 3 = Kapseln, 4 = Etiketten
  materialartikelid: number | null;
  anzahl: number;
  neuerBarcode: string;

  constructor(bestandid: number | null, bestandTyp: number) {
    this.bestandid = bestandid;
    this.bestandTyp = bestandTyp;
    this.materialartikelid = null;
    this.anzahl = 0;
    this.neuerBarcode = '';
  }
}

export class WMSMaterialBestandAuslagernRequest {
  materialartikelid: number;
  bestandTyp: number; // 1 = Karton, 2 = Flasche, 3 = Kapseln, 4 = Etiketten
  anzahl: number;
  produktionsauftragid: number | null;

  constructor(materialartikelid: number, bestandTyp: number, anzahl: number, produktionsauftragid: number | null = null) {
    this.materialartikelid = materialartikelid;
    this.bestandTyp = bestandTyp;
    this.anzahl = anzahl;
    this.produktionsauftragid = produktionsauftragid;
  }
}

import { AuthUser } from '@/models/ArchivModels';
import { MutationTree } from 'vuex';
import { State } from './state';
import { Lagerplatz, LagerplatzResponse, Lagerplatzart, Lagerreihe, Listeartikel } from '@/models/LagerModels';
import { Listeppsid } from '@/models/PpsModels';

export enum MutationTypes {
  GetArtikelImLagerSuccess = 'GETARTIKELIMLAGERSUCCESS',
  GetLagerplaetzeDatenSuccess = 'GETLAGERPLAETZESUCCESS',
  GetLagerzustandSuccess = 'GETLAGERZUSTANDSUCCESS',
  GetLagerplatzartenSuccess = 'GETLAGERPLATZARTENSUCCESS',
  GetLagerpufferDatenSuccess = 'GETLAGERPUFFERDATENSUCCESS',
  GetProduktionspufferDatenSuccess = 'GETPRODUKTIONSPUFFERPUFFERDATENSUCCESS',
}

export type Mutations = {
  [MutationTypes.GetArtikelImLagerSuccess](state: State, data: Array<Listeartikel>): void;
  [MutationTypes.GetLagerplaetzeDatenSuccess](state: State, data: Array<LagerplatzResponse>): void;
  [MutationTypes.GetLagerzustandSuccess](state: State): void;
  [MutationTypes.GetLagerplatzartenSuccess](state: State, data: Array<Lagerplatzart>): void;
  [MutationTypes.GetLagerpufferDatenSuccess](state: State, data: Array<Listeartikel>): void;
  [MutationTypes.GetProduktionspufferDatenSuccess](state: State, data: Array<Listeppsid>): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.GetArtikelImLagerSuccess](state, data) {
    state.lagerartikel = data;
  },

  [MutationTypes.GetLagerplaetzeDatenSuccess](state, data) {
    state.lagerplaetze = data;
  },

  [MutationTypes.GetLagerzustandSuccess](state) {
    let lagermatrix = new Array<Lagerreihe>();
    let platzID = 0;
    let fertigwarenStellplaetze = 0;

    state.lagerplaetze.forEach((reihe) => {
      let row: Lagerreihe = {
        id: reihe.id,
        block: reihe.block,
        reihe: reihe.reihe,
        aktiv: reihe.aktiv,
        gesperrt: reihe.gesperrt,
        lagerplatzartid: reihe.lagerplatzartid,

        lagerplatzartTitel: reihe.lagerplatzartTitel,
        lagerplatzartText: reihe.lagerplatzartText,
        lagerplatzartFarbe: reihe.lagerplatzartFarbe.toString(),
        lagerplatzart: reihe.lagerplatzart,
        bgColor: reihe.bgColor,
        textColor: getTextColor(reihe.bgColor), //'black',
        stellplaetze: [],
      };

      if (row.aktiv == 0) {
        row.textColor = 'red';
      }

      if (row.aktiv == 1 && row.lagerplatzartid == 1) {
        fertigwarenStellplaetze += reihe.maxstellplatz;
      }

      for (let i = 0; i < reihe.maxstellplatz; i++) {
        let stellplatz: Lagerplatz = {
          id: platzID,
          platz: i,
          artikel: null,
        };

        row.stellplaetze.push(stellplatz);
        platzID++;
      }

      lagermatrix.push(row);
    });

    state.fertigwarenStellplaetze = fertigwarenStellplaetze;

    state.lagerartikel.forEach((palette) => {
      let rowIndex = lagermatrix.findIndex((el) => el.id === palette.lagerplatzid);

      if (rowIndex != -1) {
        for (let i = lagermatrix[rowIndex].stellplaetze.length; i > 0; i--) {
          if (lagermatrix[rowIndex].stellplaetze[i - 1].artikel === null) {
            lagermatrix[rowIndex].stellplaetze[i - 1].artikel = palette;

            let artikelnr = lagermatrix[rowIndex].stellplaetze[i - 1].artikel.artikelnr;
            let landnr = lagermatrix[rowIndex].stellplaetze[i - 1].artikel.landnr;

            let hex = Math.floor(((artikelnr + landnr) / 1000) * 0xffffff);
            let hexString = ('000000' + hex.toString(16)).substr(-6);

            lagermatrix[rowIndex].stellplaetze[i - 1].artikel.bgColor = `#${hexString}`;
            let textColor = getTextColor(hexString);
            lagermatrix[rowIndex].stellplaetze[i - 1].artikel.textColor = textColor;
            break;
          }
        }
      }
      // else {
      //   console.error(`Reihe ${palette.lagerplatzid} | ${palette.lagerplatzname} nicht gefunden`);
      // }
    });

    state.lager = lagermatrix;
  },

  [MutationTypes.GetLagerplatzartenSuccess](state, data) {
    state.lagerplatzarten = data;
  },

  [MutationTypes.GetLagerpufferDatenSuccess](state, data) {
    state.lagerpuffer = data;
  },

  [MutationTypes.GetProduktionspufferDatenSuccess](state, data) {
    state.produktionspuffer = data;
  },
};

function getTextColor(color: any) {
  let r, g, b, hsp;

  color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

  r = color >> 16;
  g = (color >> 8) & 255;
  b = color & 255;

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is white or black
  if (hsp > 127.5) {
    return 'black';
  } else {
    return 'white';
  }
}

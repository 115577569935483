<template>
  <div id="materialFlaschenContent" class="mainContent status-text-size">
    <div class="card status-text-size p-3 border-0 shadow-sm rounded-0">
      <div class="card-header bg-white border-0">
        <div class="row">
          <div class="col ps-0">
            <p class="text-secondary mb-0">Bestand: Flaschen</p>
          </div>
          <div class="col d-flex" v-if="showBestand && !showMaterialartikelDetails && !showLieferantPalettenDeatils">
            <input type="number" class="form-control form-control-sm me-2" v-model="selectVal.materialartikelid" placeholder="Artikel ID" />
            <input type="text" class="form-control form-control-sm" v-model="selectVal.artikel" placeholder="Artikel" />
          </div>
          <div class="col text-end text-secondary pe-0">
            <span class="me-3">{{ updateDatetimeConverter }}</span>
            <span id="sync" @click="updateFlaschenBestand()">
              <font-awesome-icon icon="sync" />
            </span>
          </div>
        </div>
      </div>

      <div v-if="showBestand && !showMaterialartikelDetails && !showLieferantPalettenDeatils">
        <div class="row" v-if="flaschenBestandLoading || materialartikelFlaschenLoading">
          <div class="col m-4 text-center w-100">
            <LoadingSpinner />
          </div>
        </div>

        <table class="table table-striped table-sm table-responsive align-middle" v-else-if="getFlaschenBestandTabelle.length > 0">
          <thead>
            <tr>
              <th scope="col">Artikel ID</th>
              <th scope="col">Artikel</th>
              <th scope="col">Anzahl Paletten</th>
              <th scope="col">Anzahl Flaschen</th>
              <th scope="col">Details</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="data in filteredData">
              <td>{{ data.materialartikelid }}</td>
              <td>{{ data.artikel }}</td>
              <td>{{ data.anzahlPaletten }}</td>
              <td>{{ data.anzahlFlaschen }}</td>
              <td>
                <button class="btn btn-primary btn-sm status-text-size rounded-0" @click="showDetails(data)">
                  Details <font-awesome-icon icon="chevron-right" class="fa-sm" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else class="m-4 float-center w-100">
          <div class="alert alert-danger" role="alert">Es wurden keine Flaschen gefunden</div>
        </div>
      </div>

      <div v-if="materialartikelDetails != null && !showBestand && showMaterialartikelDetails">
        <div class="d-flex my-2">
          <button class="btn btn-primary btn-sm status-text-size rounded-0" @click="showDetailsZurueck()">
            <font-awesome-icon icon="chevron-left" class="fa-sm" /> Zurück
          </button>
          <h3 class="ms-3 my-auto">Material-Artikel: {{ materialartikelDetails.artikel }}</h3>
        </div>
        <p class="mb-3">
          <strong>Material-Ariktel-ID:</strong> {{ materialartikelDetails.materialartikelid }} | <strong>Anzahl Paletten:</strong>
          {{ materialartikelDetails.anzahlPaletten }} | <strong>Anzahl Flaschen:</strong> {{ materialartikelDetails.anzahlFlaschen }}
        </p>

        <table class="table table-striped table-sm table-responsive align-middle">
          <thead>
            <tr>
              <th scope="col">Artikel</th>
              <th scope="col">Lieferant</th>
              <!-- <th scope="col">Flaschen pro Palette</th> -->
              <th scope="col">Anzahl Paletten</th>
              <th scope="col">Anzahl Flaschen</th>
              <th scope="col">Details</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="data in flaschenBestandDetailsData">
              <td>{{ data.artikel }}</td>
              <td>{{ data.lieferant }}</td>
              <!-- <td>{{ data.flaschenProPalette }}</td> -->
              <td>{{ data.anzahlPaletten }}</td>
              <td>{{ data.anzahlFlaschen }}</td>
              <td>
                <button class="btn btn-primary btn-sm status-text-size rounded-0" @click="showPalettenDetails(data)">
                  Details <font-awesome-icon icon="chevron-right" class="fa-sm" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="palettenDetails != null">
        <div class="d-flex my-2">
          <button class="btn btn-primary btn-sm status-text-size rounded-0" @click="showPalettenDetailsZurueck()">
            <font-awesome-icon icon="chevron-left" class="fa-sm" /> Zurück
          </button>
          <h3 class="ms-3 my-auto flex-fill">Artikel: {{ palettenDetails.artikel }}</h3>
          <button class="btn btn-sm status-text-size rounded-0" @click="printList()"><font-awesome-icon icon="print" class="fa-lg" /></button>
        </div>
        <p class="mb-3">
          <strong>Lieferant:</strong> {{ palettenDetails.lieferant }} | <strong>Anzahl Paletten:</strong> {{ palettenDetails.anzahlPaletten }} |
          <strong>Anzahl Flaschen:</strong> {{ palettenDetails.anzahlFlaschen }}
        </p>

        <table id="palettenBestand" class="table table-striped table-sm table-responsive align-middle">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Barcode</th>
              <th scope="col">Flaschen pro Palette</th>
              <th scope="col">Materialartikel ID</th>
              <th scope="col">Lieferdatum</th>
              <th scope="col">Mitarbeiter</th>
              <th scope="col">Details</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="data in palettenDetails.lieferantArtikel">
              <td>{{ data.id }}</td>
              <td>{{ data.barcode }}</td>
              <td>{{ data.anzahlstueck }}</td>
              <td>{{ data.materialartikelid }}</td>
              <td>{{ lieferdatumConverter(data.lieferdatum) }}</td>
              <td>{{ data.mitarbeiter }}</td>
              <td>
                <button class="btn btn-primary btn-sm status-text-size rounded-0" @click="showPaletteInfo(data)">
                  Details <font-awesome-icon icon="chevron-right" class="fa-sm" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div id="pdfPrintContent" v-if="displayPrintData">
          <h3>Artikel: {{ palettenDetails.artikel }}</h3>
          <p class="mb-3">
            <strong>Lieferant:</strong> {{ palettenDetails.lieferant }} | <strong>Anzahl Paletten:</strong> {{ palettenDetails.anzahlPaletten }} |
            <strong>Anzahl Flaschen:</strong> {{ palettenDetails.anzahlFlaschen }}
          </p>

          <table>
            <thead>
              <tr>
                <th class="p-1"></th>
                <th class="p-1"></th>
                <th class="p-1 pe-5">Barcode</th>
                <th class="p-1 pe-5">Anzahl Flaschen</th>
                <th class="p-1">Lieferdatum</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(data, index) in palettenDetails.lieferantArtikel.sort((a: any, b: any) => (a.barcode < b.barcode ? -1 : 1))" :key="index">
                <td class="border-bottom border-dark p-1">{{ index + 1 }}</td>
                <td class="border-bottom border-dark p-1"><input type="checkbox" /></td>
                <td class="border-bottom border-dark p-1 pe-5">{{ data.barcode }}</td>
                <td class="border-bottom border-dark p-1 pe-5">{{ data.anzahlstueck }}</td>
                <td class="border-bottom border-dark p-1">{{ lieferdatumConverter(data.lieferdatum) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="materialartikelDetails != null && palettenDetails != null && palette != null"
    class="modal fade"
    :id="'paletteModal-' + materialartikelDetails.id"
    tabindex="-1"
    :aria-labelledby="'paletteModal-' + materialartikelDetails.id"
    aria-hidden="true"
    data-bs-keyboard="false"
    ref="paletteModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header py-1">
          <h5 class="modal-title" :id="'PaletteModalLabel-' + materialartikelDetails.id">Palette</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col">
              <p><strong>Materialartikel:</strong> {{ materialartikelDetails.artikel }}</p>
            </div>
            <div class="col">
              <p><strong>Materialartikel ID:</strong> {{ palette.materialartikelid }}</p>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col">
              <p><strong>Artikel:</strong> {{ palettenDetails.artikel }}</p>
            </div>
            <div class="col">
              <p><strong>ID:</strong> {{ palette.id }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p><strong>Lieferant:</strong> {{ palettenDetails.lieferant }}</p>
            </div>
            <div class="col">
              <p><strong>Anzahl Flaschen:</strong> {{ palette.anzahlstueck }}</p>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col">
              <p><strong>Barcode:</strong> {{ palette.barcode }}</p>
              <p><strong>Artikel Nr.:</strong> {{ palette.artikelnr }}</p>
            </div>
            <div class="col">
              <p><strong>Materialbestellung ID:</strong> {{ palette.materialbestellungid }}</p>
              <p><strong>Materiallieferung ID:</strong> {{ palette.materiallieferungid }}</p>
              <p><strong>Materiallieferung Position ID:</strong> {{ palette.materiallieferposid }}</p>
            </div>
          </div>

          <br />

          <div class="row">
            <div class="col">
              <p><strong>Lieferdatum:</strong> {{ paletteLieferdatum }}</p>
              <p><strong>Inventurdatum:</strong> {{ paletteInventurdatum }}</p>
            </div>
            <div class="col">
              <p><strong>Lagerabgangdatum:</strong> {{ paletteLagerabgangdatum }}</p>
              <p><strong>Lagerabgangmaschinen ID:</strong> {{ palette.lagerabgangmaschineid }}</p>
            </div>
          </div>

          <br />

          <div class="row">
            <div class="col">
              <p><strong>Produktionsdatum:</strong> {{ paletteProduktionsdatum }}</p>
              <p><strong>Produktionsplan ID:</strong> {{ palette.produktionsplanid }}</p>
            </div>
            <div class="col">
              <p><strong>Maschine ID:</strong> {{ palette.maschineid }}</p>
              <p><strong>Maschine Nr.:</strong> {{ palette.maschinenr }}</p>
            </div>
          </div>

          <br />

          <div class="row">
            <div class="col">
              <p><strong>Mitarbeiter</strong> {{ palette.mitarbeiter }}</p>
            </div>
            <div class="col">
              <p><strong>Lagerplatz</strong> {{ palette.lagerplatzname }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import LoadingSpinner from '@/components/LoadingSpinner.vue';
  import { Modal } from 'bootstrap';

  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from '@/store';

  import { ActionTypes as MatrialActionTypes } from '@/store/modules/Pps/Material/actions';

  import { Flasche } from '@/models/PpsMaterialModels';

  import _ from 'lodash';
  import moment from 'moment';
  import { jsPDF } from 'jspdf';

  export default defineComponent({
    name: 'MaterialContent',

    components: {
      LoadingSpinner,
    },

    setup() {
      const store = useStore();

      const materialFlaschen = computed(() => store.getters.materialFlaschen);
      const materialArtikelFlaschen = computed(() => store.getters.materialartikelFlaschen);
      const flaschenBestandLoading = computed(() => store.getters.status.flaschenBestandLoading);
      const materialartikelFlaschenLoading = computed(() => store.getters.status.materialartikelFlaschenLoading);

      // const datum = computed(() => {
      //   var date = new Date()
      //   return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + (date.getMinutes() + 1)).slice(-2);
      // })
      const currentMaterialartikelId = ref<number | null>(null);
      const prepDetailsData = ref(true);
      const materialartikelDetails = ref();
      const palettenDetails = ref();
      const showBestand = ref(true);
      const showMaterialartikelDetails = ref(false);
      const showLieferantPalettenDeatils = ref(false);
      const palette = ref<Flasche>();
      const selectVal = ref({
        artikel: '',
        materialartikelid: '',
      });

      const filteredData = computed(() => {
        let artikelFilter = getFlaschenBestandTabelle.value;
        if (selectVal.value.artikel !== '') {
          artikelFilter = artikelFilter.filter((item) => {
            if (!item.artikel) {
              item.artikel = 'unbekannt';
            }
            return item.artikel.toLowerCase().match(selectVal.value.artikel.toString().toLowerCase());
          });
        }

        let artikelIdFilter = artikelFilter;
        if (selectVal.value.materialartikelid !== null) {
          artikelIdFilter = artikelFilter.filter((item) => {
            if (!item.materialartikelid) {
              item.materialartikelid = 'unbekannt';
            }
            return item.materialartikelid.toString().match(selectVal.value.materialartikelid.toString());
          });
        }

        return artikelIdFilter;
      });

      const getFlaschenBestandTabelle = computed(() => {
        let tableItems = [];

        let data = groupedByMaterialArtikelId.value;

        for (let materialartikelid in data) {
          let count = 0;
          let materialartikel = materialArtikelFlaschen.value.find((x) => x.id == parseInt(materialartikelid));

          let artikel = materialartikel ? materialartikel.artikel : 'unbekannt';

          for (let palette in data[materialartikelid]) {
            count += data[materialartikelid][palette].anzahlstueck;
          }

          tableItems.push({
            materialartikelid: materialartikelid,
            artikel: artikel || 'unbekannt',
            anzahlPaletten: data[materialartikelid].length,
            anzahlFlaschen: count,
          });
        }

        return tableItems;
      });

      const artikelGroupByArtikel = (data: Array<Flasche>): Array<Flasche> => {
        return _.mapValues(_.groupBy(data, 'artikel'), (plist: any[]) => plist.map((pers) => _.omit(pers, 'artikel')));
      };

      const artikelGroupedByLieferant = (data: any) => {
        return _.mapValues(_.groupBy(data, 'liefernr'), (plist: any[]) => plist.map((pers) => _.omit(pers, 'liefernr')));
      };

      const groupedByMaterialArtikelId = computed(() => {
        return _.mapValues(_.groupBy(materialFlaschen.value, 'materialartikelid'), (plist: any[]) => plist.map((pers) => _.omit(pers, 'materialartikelid')));
      });

      const flaschenBestandDetailsData = computed(() => {
        let flaschenDetailsBestand = materialFlaschen.value.filter((x) => {
          if (currentMaterialartikelId.value == null) {
            return !x.materialartikelid && x.materialartikelid !== 0;
          }

          return x.materialartikelid == currentMaterialartikelId.value;
        });

        let artikelGroupedByArtikel = artikelGroupByArtikel(flaschenDetailsBestand);
        let tableItems = [];

        for (let artikel in artikelGroupedByArtikel) {
          let lieferantArtikel = artikelGroupedByLieferant(artikelGroupedByArtikel[artikel]);

          for (let lieferant in lieferantArtikel) {
            let anzahlFlaschen = 0;
            lieferantArtikel[lieferant].forEach((x: any) => {
              anzahlFlaschen += x.anzahlstueck;
            });

            /**
             * Anzahl Flaschen Pro Palette kann falsch sein - Anzahl Flaschen ist in jedem Fall richtig
             */
            tableItems.push({
              artikel: artikel || 'unbekannt',
              lieferant: lieferant,
              flaschenProPalette: lieferantArtikel[lieferant][0].anzahlstueck,
              anzahlPaletten: lieferantArtikel[lieferant].length,
              anzahlFlaschen: anzahlFlaschen,
              lieferantArtikel: lieferantArtikel[lieferant],
            });
          }
        }

        return tableItems;
      });

      const paletteLieferdatum = computed(() => {
        return moment(String(palette.value?.lieferdatum)).format('DD.MM.YYYY HH:mm:ss');
      });

      const paletteInventurdatum = computed(() => {
        return palette.value?.inventurdatum ? moment(String(palette.value?.inventurdatum)).format('DD.MM.YYYY HH:mm:ss') : '';
      });

      const paletteLagerabgangdatum = computed(() => {
        return palette.value?.lagerabgangdatum ? moment(String(palette.value?.lagerabgangdatum)).format('DD.MM.YYYY HH:mm:ss') : '';
      });

      const paletteProduktionsdatum = computed(() => {
        return palette.value?.produktiondatum ? moment(String(palette.value?.produktiondatum)).format('DD.MM.YYYY HH:mm:ss') : '';
      });

      const paletteModalRef = ref();
      const closeEmailModal = () => Modal.getInstance(paletteModalRef.value)?.hide();
      const showEmailModal = () => {
        if (paletteModalRef.value) {
          new Modal(paletteModalRef.value).show();
        }
      };

      const updateStatusInterval = ref();
      const updateDatetime = ref<Date>(new Date());
      const updateDatetimeConverter = computed(() => {
        return ('0' + updateDatetime.value.getHours()).slice(-2) + ':' + ('0' + updateDatetime.value.getMinutes()).slice(-2);
      });

      const displayPrintData = ref(false);

      return {
        store,
        materialFlaschen,
        flaschenBestandLoading,
        materialartikelFlaschenLoading,
        materialArtikelFlaschen,
        // datum,
        currentMaterialartikelId,
        prepDetailsData,
        materialartikelDetails,
        palettenDetails,
        showBestand,
        showMaterialartikelDetails,
        showLieferantPalettenDeatils,
        palette,
        selectVal,
        filteredData,
        paletteLieferdatum,
        paletteInventurdatum,
        paletteLagerabgangdatum,
        paletteProduktionsdatum,
        getFlaschenBestandTabelle,
        flaschenBestandDetailsData,
        paletteModalRef,
        closeEmailModal,
        showEmailModal,
        updateStatusInterval,
        updateDatetime,
        updateDatetimeConverter,
        displayPrintData,
      };
    },

    methods: {
      getFlaschenBestand() {
        this.store.dispatch(MatrialActionTypes.GetFlaschenBestand, undefined).then(() => {
          this.updateDatetime = new Date();
        });
      },

      updateGetFlaschenBestand() {
        this.store.dispatch(MatrialActionTypes.UpdateGetFlaschenBestand, undefined).then(() => {
          this.updateDatetime = new Date();
        });
      },

      updateFlaschenBestand() {
        this.getFlaschenBestand();
      },

      showDetails(data: any) {
        /* View */
        this.showBestand = false;
        this.showMaterialartikelDetails = true;
        this.showLieferantPalettenDeatils = false;

        /* Data */
        this.materialartikelDetails = data;
        this.currentMaterialartikelId = data.materialartikelid;
      },

      showDetailsZurueck() {
        /* View */
        this.showBestand = true;
        this.showMaterialartikelDetails = false;
        this.showLieferantPalettenDeatils = false;

        /* Data */
        this.materialartikelDetails = null;
        this.currentMaterialartikelId = null;
        this.palettenDetails = null;
      },

      showPalettenDetails(data: any) {
        /* View */
        this.showBestand = false;
        this.showMaterialartikelDetails = false;
        this.showLieferantPalettenDeatils = true;

        /* Data */
        this.palettenDetails = data;
      },

      showPalettenDetailsZurueck() {
        /* View */
        this.showBestand = false;
        this.showMaterialartikelDetails = true;
        this.showLieferantPalettenDeatils = false;

        /* Data */
        this.palettenDetails = null;
        this.palette = undefined;
      },

      showPaletteInfo(palette: Flasche) {
        this.palette = palette;
        this.showEmailModal();
      },

      lieferdatumConverter(datestring: string) {
        const date = new Date(datestring);
        return (
          ('0' + date.getDate()).slice(-2) +
          '.' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '.' +
          date.getFullYear() +
          ' ' +
          ('0' + date.getHours()).slice(-2) +
          ':' +
          ('0' + (date.getMinutes() + 1)).slice(-2)
        );
      },

      printList() {
        this.displayPrintData = true;

        // Warten 100ms bis Content gerendert wurde
        setTimeout(async () => {
          const doc = new jsPDF('p', 'mm', 'a4');
          const element = document.getElementById('pdfPrintContent');
          if (element != null) {
            const date = new Date();
            const docTitel = 'Flaschen_' + this.materialartikelDetails.artikel + '_' + date.toISOString().split('T')[0] + '.pdf';

            await doc.html(element, {
              autoPaging: true,
              margin: 5,
              width: 200,
              windowWidth: element.offsetWidth,
            });

            doc.save(docTitel);
            this.displayPrintData = false;
          }
        }, 100);
      },
    },

    mounted() {
      if (this.materialFlaschen.length == 0) {
        this.getFlaschenBestand();
      }

      this.updateStatusInterval = setInterval(
        () => {
          this.updateGetFlaschenBestand();
        },
        5 * 60 * 1000
      );
    },

    beforeUnmount() {
      clearInterval(this.updateStatusInterval);
    },
  });
</script>

<style lang="scss" scoped>
  #sync:hover {
    color: #dc3545;
    cursor: pointer;
  }
  // #materialFlaschenContent {
  //   background: rgb(242, 243, 248);
  // }
</style>

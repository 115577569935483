import { ActionContext, ActionTree } from 'vuex';
import ArchivApiService from '@/service/ArchivApiService';

import { RootState } from '@/store';

import { State } from './state';
import router from '@/router';

import { Mutations, MutationTypes } from './mutations';
import { AuthUser, LoginRequest, LogoutRequest, LoginAzureRequest, RefreshLoginRequest, AzureConfigEinstellung } from '@/models/ArchivModels';

import { ActionTypes as CommonActionTypes } from '../Common/actions';

export enum ActionTypes {
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  RefreshLogin = 'REFRESHLOGIN',

  LoginAzure = 'LOGINAZURE',
  GetAzureLoginConfig = 'GETAZURELOGINCONFIG',
  SetAzureLoginAvailable = 'SETAZURELOGINAVAILABLE',
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.Login](context: ActionArguments, data: LoginRequest): Promise<void>;
  [ActionTypes.LoginAzure](context: ActionArguments, azureLogin: LoginAzureRequest): Promise<void>;
  [ActionTypes.RefreshLogin](context: ActionArguments): Promise<void>;
  [ActionTypes.Logout](context: ActionArguments): Promise<void>;
  [ActionTypes.GetAzureLoginConfig](context: ActionArguments): void;
  [ActionTypes.SetAzureLoginAvailable](context: ActionArguments): void;
};

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.Login]({ commit, dispatch, rootGetters }: ActionArguments, data: LoginRequest) {
    const status = rootGetters.status;
    status.archivErrorMsg = '';
    status.archivLoading = true;
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetLoading, true);

    return ArchivApiService.login(data)
      .then((res) => {
        commit(MutationTypes.SetLoading, false);

        const user: AuthUser = res.data;
        commit(MutationTypes.LoginSuccess, user);
        localStorage.setItem('authUser', JSON.stringify(user));

        status.archivLoading = false;
        status.archivLoggedIn = true;
        dispatch(CommonActionTypes.SetStatus, status);

        router.push({ name: 'home' });
      })
      .catch((error) => {
        status.archivLoading = false;
        status.archivLoggedIn = false;
        status.archivErrorMsg = error.response.data.title;
        dispatch(CommonActionTypes.SetStatus, status);

        commit(MutationTypes.SetLoading, false);
        commit(MutationTypes.AuthRequestFailure, error.response.data.title);
        localStorage.removeItem('authUser');
      });
  },

  [ActionTypes.LoginAzure]({ commit, dispatch, rootGetters }: ActionArguments, azureLogin: LoginAzureRequest) {
    const status = rootGetters.status;
    status.archivErrorMsg = '';
    status.archivLoading = true;
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.loginAzure(azureLogin.idToken)
      .then(async (res) => {
        commit(MutationTypes.SetAzureLogin, true);

        const user: AuthUser = res.data;
        commit(MutationTypes.LoginSuccess, user);
        localStorage.setItem('authUser', JSON.stringify(user));

        status.authLoading = false;
        status.archivLoggedIn = true;
        dispatch(CommonActionTypes.SetStatus, status);

        router.push({ name: 'home' });
      })
      .catch((error) => {
        status.authLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);

        // commit(MutationTypes.AuthRequestFailure, error.response.data.title);
        commit(MutationTypes.AuthRequestFailure, 'Anmeldung fehlgeschlagen');
        localStorage.removeItem('authUser');
      });
  },

  async [ActionTypes.RefreshLogin]({ commit, dispatch, rootGetters }: ActionArguments) {
    const request = new RefreshLoginRequest(rootGetters.authUser.refreshToken);
    return ArchivApiService.refreshLogin(request).then((res) => {
      const result: AuthUser = res.data;
      commit(MutationTypes.RefreshLoginSuccess, result);
      localStorage.setItem('authUser', JSON.stringify(result));
    });
  },

  async [ActionTypes.Logout]({ commit, dispatch, rootGetters }: ActionArguments) {
    const status = rootGetters.status;
    status.archivErrorMsg = '';
    status.archivLoading = true;
    dispatch(CommonActionTypes.SetStatus, status);
    commit(MutationTypes.SetLoading, true);

    await ArchivApiService.logout(new LogoutRequest(rootGetters.authUser.refreshToken));

    status.archivLoading = false;
    status.archivLoggedIn = false;
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetLoading, false);
    commit(MutationTypes.Logout, undefined);
    commit(MutationTypes.SetAzureLogin, false);

    return;
  },

  async [ActionTypes.GetAzureLoginConfig]({ commit }: ActionArguments) {
    ArchivApiService.getAzureLoginConfig()
      .then((res) => {
        const azureconfig: AzureConfigEinstellung = res.data;
        commit(MutationTypes.GetAzureLoginConfigSuccess, azureconfig);
        localStorage.setItem('azureconfig', JSON.stringify(azureconfig));
      })
      .catch((error) => {
        localStorage.removeItem('azureconfig');
        console.error(error);
      });
  },

  [ActionTypes.SetAzureLoginAvailable]({ commit }: ActionArguments) {
    commit(MutationTypes.SetAzureLoginAvailable, undefined);
  },
};

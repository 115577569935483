<template>
  <div id="lagerLayout">
    <div class="row">
      <div class="col-7 ps-0 pe-1">
        <!-- G -->
        <div class="mb-2 border border-2">
          <div class="row mx-0" v-for="row in getBlockG" :key="row.id" :style="{ background: row.bgColor, color: row.textColor }">
            <div class="col pb-1 border-bottom">
              <div class="float-left reihe-name">{{ row.block }}{{ reiheNummer(row) }} | {{ getAnzahlPalettenInReihe(row) }}:</div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>

        <!-- D -->
        <div class="mb-2 border border-2">
          <div class="row mx-0" v-for="row in getBlockD" :key="row.id" :style="{ background: row.bgColor, color: row.textColor }">
            <div class="col pb-1 border-bottom">
              <div class="float-left reihe-name">{{ row.block }}{{ reiheNummer(row) }} | {{ getAnzahlPalettenInReihe(row) }}:</div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>

        <!-- C -->
        <div class="mb-2 border border-2">
          <div class="row mx-0" v-for="row in getBlockC" :key="row.id" :style="{ background: row.bgColor, color: row.textColor }">
            <div class="col pb-1 border-bottom">
              <div class="float-left reihe-name">{{ row.block }}{{ reiheNummer(row) }} | {{ getAnzahlPalettenInReihe(row) }}:</div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>

        <!-- B -->
        <div class="mb-2 border border-2">
          <div class="row mx-0" v-for="row in getBlockB" :key="row.id" :style="{ background: row.bgColor, color: row.textColor }">
            <div class="col pb-1 border-bottom">
              <div class="float-left reihe-name">{{ row.block }}{{ reiheNummer(row) }} | {{ getAnzahlPalettenInReihe(row) }}:</div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>

        <!-- F -->
        <div class="mb-2 border border-2">
          <div class="row mx-0" v-for="row in getBlockF" :key="row.id" :style="{ background: row.bgColor, color: row.textColor }">
            <div class="col pb-1 border-bottom">
              <div class="float-left reihe-name">{{ row.block }}{{ reiheNummer(row) }} | {{ getAnzahlPalettenInReihe(row) }}:</div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-5 ps-1 pe-0">
        <!-- X -->
        <div class="mb-2 border border-2">
          <div class="row mx-0" v-for="row in getBlockX" :key="row.id" :style="{ background: row.bgColor, color: row.textColor }">
            <div class="col pb-1 border-bottom">
              <div class="float-left reihe-name">{{ row.block }}{{ reiheNummer(row) }} | {{ getAnzahlPalettenInReihe(row) }}:</div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>

        <!-- H -->
        <div class="mb-2 border border-2">
          <div class="row mx-0" v-for="row in getBlockH" :key="row.id" :style="{ background: row.bgColor, color: row.textColor }">
            <div class="col pb-1 border-bottom">
              <div class="float-left reihe-name">{{ row.block }}{{ reiheNummer(row) }} | {{ getAnzahlPalettenInReihe(row) }}:</div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>

        <!-- E -->
        <div class="mb-2 border border-2">
          <div class="row mx-0" v-for="row in getBlockE" :key="row.id" :style="{ background: row.bgColor, color: row.textColor }">
            <div class="col pb-1 border-bottom">
              <div class="float-left reihe-name">{{ row.block }}{{ reiheNummer(row) }} | {{ getAnzahlPalettenInReihe(row) }}:</div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4 d-flex flex-column p-2 shadow bg-white">
        <div>
          <p class="mb-0 reihe-name">000 | {{ getAnzahlPalettenInPuffer }}:</p>
          <div v-for="palette in lagerpuffer" :key="palette.id">
            <LagerpufferPalette :palette="palette" />
          </div>
        </div>

        <hr class="my-2" />

        <div>
          <p class="mb-0 text-start reihe-name">PPS | {{ getAnzahlPalettenInProduktionspuffer }}:</p>
          <div v-for="palette in produktionspuffer" :key="palette.id">
            <ProduktionspufferPalette :palette="palette" />
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="bg-secondary text-white shadow p-2">
          <div class="d-flex justify-content-between">
            <p class="mb-0">
              <strong> Lagerbestand {{ lagerartikel.length }} Paletten / {{ fertigwarenStellplaetze }} Stellplätze ({{ getLagerAuslastung }}) </strong>
            </p>

            <p class="mb-0">{{ aktualisiertam }}</p>
          </div>

          <hr class="my-2" />

          <div class="row mb-2">
            <div class="col-4 my-auto"><strong>A:</strong> {{ getAnzahlPalettenBlockA }} Pal.</div>
            <div class="col-4 my-auto"><strong>B:</strong> {{ getAnzahlPalettenBlockB }} Pal.</div>
            <div class="col-4 my-auto"><strong>C:</strong> {{ getAnzahlPalettenBlockC }} Pal.</div>
          </div>

          <div class="row mb-2">
            <div class="col-4 my-auto"><strong>D:</strong> {{ getAnzahlPalettenBlockD }} Pal.</div>
            <div class="col-4 my-auto"><strong>E:</strong> {{ getAnzahlPalettenBlockE }} Pal.</div>
            <div class="col-4 my-auto"><strong>F:</strong> {{ getAnzahlPalettenBlockF }} Pal.</div>
          </div>

          <div class="row mb-2">
            <div class="col-4 my-auto"><strong>G:</strong> {{ getAnzahlPalettenBlockG }} Pal.</div>
            <div class="col-4 my-auto"><strong>H:</strong> {{ getAnzahlPalettenBlockH }} Pal.</div>
            <div class="col-4 my-auto"><strong>X:</strong> {{ getAnzahlPalettenBlockX }} Pal.</div>
          </div>

          <div class="row mb-2">
            <div class="col-4 my-auto"><strong>TST:</strong> {{ getAnzahlPalettenBlockTST }} Pal.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useStore } from '@/store';

  import { Lagerreihe, Listeartikel } from '@/models/LagerModels';
  import LagerStellplatz from './LagerStellplatz.vue';
  import LagerpufferPalette from './LagerpufferPalette.vue';
  import ProduktionspufferPalette from './ProduktionspufferPalette.vue';
  import LoadingSpinner from '@/components/LoadingSpinner.vue';

  export default defineComponent({
    name: 'LagerLayout',
    components: {
      LagerStellplatz,
      LagerpufferPalette,
      ProduktionspufferPalette,
      LoadingSpinner,
    },

    setup() {
      const store = useStore();
      const lager = computed(() => store.getters.lager.filter((l) => l.lagerplatzartid == 1));
      const lagerartikel = computed(() => store.getters.lagerartikel);

      const fertigwarenStellplaetze = computed(() => store.getters.fertigwarenStellplaetze);
      const lagerpuffer = computed(() => store.getters.lagerpuffer);
      const produktionspuffer = computed(() => store.getters.produktionspuffer);

      return {
        store,
        lager,
        lagerartikel,
        fertigwarenStellplaetze,
        lagerpuffer,
        produktionspuffer,
      };
    },

    props: {
      aktualisiertam: {
        type: String,
        required: true,
      },
    },
    computed: {
      getBlockA() {
        return this.lager.filter((el: Lagerreihe) => el.block == 'A').reverse();
      },
      getBlockB() {
        return this.lager.filter((el: Lagerreihe) => el.block == 'B').reverse();
      },
      getBlockC() {
        return this.lager.filter((el: Lagerreihe) => el.block == 'C').reverse();
      },
      getBlockD() {
        return this.lager.filter((el: Lagerreihe) => el.block == 'D').reverse();
      },
      getBlockE() {
        return this.lager.filter((el: Lagerreihe) => el.block == 'E').reverse();
      },
      getBlockF() {
        return this.lager.filter((el: Lagerreihe) => el.block == 'F').reverse();
      },
      getBlockG() {
        return this.lager.filter((el: Lagerreihe) => el.block == 'G').reverse();
      },
      getBlockH() {
        return this.lager.filter((el: Lagerreihe) => el.block == 'H').reverse();
      },
      getBlockX() {
        return this.lager.filter((el: Lagerreihe) => el.block == 'X').reverse();
      },
      getAnzahlPalettenBlockA() {
        return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('A')).length;
      },
      getAnzahlPalettenBlockB() {
        return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('B')).length;
      },
      getAnzahlPalettenBlockC() {
        return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('C')).length;
      },
      getAnzahlPalettenBlockD() {
        return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('D')).length;
      },
      getAnzahlPalettenBlockE() {
        return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('E')).length;
      },
      getAnzahlPalettenBlockF() {
        return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('F')).length;
      },
      getAnzahlPalettenBlockG() {
        return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('G')).length;
      },
      getAnzahlPalettenBlockH() {
        return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('H')).length;
      },
      getAnzahlPalettenBlockX() {
        return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('X')).length;
      },
      getAnzahlPalettenBlockTST() {
        return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('TST')).length;
      },
      getLagerAuslastung() {
        return `${Math.floor((this.lagerartikel.length / this.fertigwarenStellplaetze) * 100)}%`;
      },
      getAnzahlPalettenInPuffer() {
        return this.lagerpuffer.length < 10 ? `0${this.lagerpuffer.length}` : this.lagerpuffer.length;
      },
      getAnzahlPalettenInProduktionspuffer() {
        return this.produktionspuffer.length < 10 ? `0${this.produktionspuffer.length}` : this.produktionspuffer.length;
      },
    },

    methods: {
      reiheNummer(row: Lagerreihe) {
        return row.reihe < 10 ? `0${row.reihe}` : row.reihe;
      },
      getAnzahlPalettenInReihe(row: Lagerreihe) {
        let count = 0;
        row.stellplaetze.forEach((el) => {
          if (el.artikel != null) {
            count++;
          }
        });

        return count < 10 ? `0${count}` : count;
      },
    },
  });
</script>

<style scoped>
  .reihe-name {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 0.7em;
  }
</style>
